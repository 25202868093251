import {
  SubscriptionActionsTree,
  SubscriptionGettersTree, SubscriptionMutationsTree,
  SubscriptionState
} from '@web/store/types/modules/subscription'
import { Subscription, RawSubscription } from '@web/types/Subscription'
import SubscriptionApi from '@web/api/modules/Subscription'
import i18n from '@web/plugins/i18n'
import { getNumberDecliner } from '@web/common/Utils'
import Time from '@web/common/Time'
import { SUBSCRIPTION_TYPE } from '@web/consts/Subscription'

const state: SubscriptionState = {
  isLoaded: false,
  subscriptions: new Map<number, RawSubscription>(),
  activeSubscriptions: new Map<number, RawSubscription>(),
  hasSubscription: false
}

function getSubscription (subscription: RawSubscription): Subscription {
  let period = ''
  if (subscription.period && subscription.interval) {
    const key = subscription.interval.toLowerCase()

    const periodText = getNumberDecliner({
      n: subscription.period,
      single: i18n.global.t(`subscription.${key}`),
      multiple: i18n.global.t(`subscription.${key}s`),
      multiple2: i18n.global.t(`subscription.${key}s2`)
    })

    period = i18n.global.t('SubscribeOn') + ' ' + subscription.period + ' ' + periodText
  }
  let accessText = i18n.global.t('subscription.ended')
  if (subscription.isActive) {
    accessText = subscription.isActiveStatus ? i18n.global.t('subscription.nextPay') : i18n.global.t('subscription.ending')
  }
  let endDate = subscription.nextPayAt ? `${accessText} ${Time(subscription.nextPayAt).format('DD MMMM YYYY')}` : ''
  if (subscription.isLifetime) {
    endDate = i18n.global.t('subscription.validIndefinitely')
  }

  return {
    id: subscription.id,
    status: subscription.status,
    isLifetime: subscription.isLifetime,
    isActive: subscription.isActive,
    isActiveStatus: subscription.isActiveStatus,
    title: subscription.title,
    period,
    endDate,
    nextPayAt: subscription.nextPayAt,
    cancelPopupText: subscription.cancelPopupText || '',
    raw: subscription,
    courseServiceType: subscription.courseServiceType
  }
}

const getters: SubscriptionGettersTree = {
  subscription: state => (id: number) => {
    const subscription: RawSubscription | undefined = state.subscriptions.get(id)
    if (!subscription) {
      return undefined
    }

    return getSubscription(subscription)
  },
  rawSubscriptions: state => Array.from(state.subscriptions.values())
    .filter(subscription => subscription.courseServiceType !== SUBSCRIPTION_TYPE.MOBILE),
  subscriptions: state => Array.from(state.subscriptions.values())
    .filter(subscription => subscription.courseServiceType !== SUBSCRIPTION_TYPE.MOBILE)
    .map(subscription => getSubscription(subscription)),
  activeSubscriptions: state => Array.from(state.activeSubscriptions.values())
    .filter(activeSubscription => activeSubscription.courseServiceType !== SUBSCRIPTION_TYPE.MOBILE)
    .map(activeSubscription => getSubscription(activeSubscription)),
  hasSubscription: (state) => state.hasSubscription,
  isLoaded: state => state.isLoaded,
  isMobileSubscription: (state, { hasSubscription }) => {
    if (!state.isLoaded) {
      return false
    }

    if (hasSubscription) {
      return false
    }
    const subscriptionsMain = Array.from(state.subscriptions.values())
      .filter(subscription => subscription.courseServiceType === SUBSCRIPTION_TYPE.MAIN)
    if (subscriptionsMain.length) {
      return false
    }
    const subscriptionsMobile = Array.from(state.subscriptions.values())
      .filter(subscription => subscription.courseServiceType === SUBSCRIPTION_TYPE.MOBILE)

    return Boolean(subscriptionsMobile.length)
  }
}

const mutations: SubscriptionMutationsTree = {
  setHasSubscription (state: SubscriptionState, payload: boolean) {
    state.hasSubscription = payload
  },
  receiveActiveSubscriptions (state: SubscriptionState, payload: RawSubscription[]) {
    for (const subscription of payload) {
      state.activeSubscriptions.set(subscription.id, subscription)
    }
  },
  receiveSubscriptions (state: SubscriptionState, payload: RawSubscription[]) {
    for (const subscription of payload) {
      state.subscriptions.set(subscription.id, subscription)
    }
  },
  receiveSubscription (state: SubscriptionState, payload: RawSubscription) {
    state.subscriptions.set(payload.id, payload)
  },
  setIsLoaded (state: SubscriptionState, payload: boolean) {
    state.isLoaded = payload
  },
  logout (state: SubscriptionState) {
    state.isLoaded = false
    state.subscriptions.clear()
  }
}

const actions: SubscriptionActionsTree = {
  fetchSubscriptions ({ commit }): Promise<void> {
    commit('setIsLoaded', false)
    return SubscriptionApi.fetchAll()
      .then(subscriptions => {
        commit('setHasSubscription', subscriptions.some(s => s.isActive))
        commit('receiveActiveSubscriptions', subscriptions.filter(s => s.isActive || s.isLifetime))
        commit('receiveSubscriptions', subscriptions)
        commit('setIsLoaded', true)
      })
  },
  fetchCachedSubscriptions ({ state, dispatch }): Promise<void> {
    if (state.isLoaded) {
      return Promise.resolve()
    }
    return dispatch('fetchSubscriptions')
  },
  fetchSubscription ({ commit }, id: number): Promise<void> {
    return SubscriptionApi.fetch(id)
      .then(subscription => {
        commit('receiveSubscription', subscription)
      })
  },
  cancelSubscription (_, payload: { id: number, reasonType?: string, reason?: string }): Promise<void> {
    return SubscriptionApi.cancel(payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
