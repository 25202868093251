/* istanbul ignore file */
import client from '../client'
import BannerMapper from '@web/mapper/BannerMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class Banner {
  static fetchAll () {
    return client.get('/banners')
      .then((response) => {
        return {
          data: response.data.map(BannerMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
